<template>
  <div>
    <KTCodePreview v-bind:title="'Tồn ảo sản phẩm'">
      <template v-slot:toolbar>
        <div class="row">
          <b-button
            v-show="checkPermission('VIRTUAL_STOCK_INSERT')"
            variant="primary"
            size="sm"
            @click="addNew"
            class="font-weight-bolder"
          >
            <i style="font-size: 1rem" class="flaticon2-add-1"></i>Tạo mới
          </b-button>
        </div>
      </template>
      <template v-slot:preview>
        <b-row class="mb-4">
          <b-col md="3">
            <b-input
              placeholder="Tìm theo tên nhóm sản phẩm"
              v-model="searchName"
              v-on:keyup.enter="onFilter()"
              size="sm"
            />
          </b-col>
          <b-col md="3">
            <b-input
              placeholder="Tìm theo tên,mã, mã vạch sản phẩm"
              v-model="searchProduct"
              v-on:keyup.enter="onFilter()"
              size="sm"
            />
          </b-col>
          <b-col md="3">
            <b-form-select
              size="sm"
              v-model="selectedStatus"
              :options="listStatus"
              value-field="id"
              text-field="name"
            ></b-form-select>
          </b-col>
          <b-col md="1">
            <b-button
              size="sm"
              variant="primary"
              style="width: 70px"
              @click="onFilter()"
              >Lọc</b-button
            >
          </b-col>
        </b-row>
        <b-table
          :items="listItem"
          :fields="fields"
          class="table-bordered table-hover"
          :busy="onLoading"
        >
          <template v-slot:table-busy>
            <vcl-table :speed="5" :animate="true" :columns="5"></vcl-table>
          </template>
          <template v-slot:cell(isActive)="row">
            <span
              v-if="row.item.isActive"
              v-text="row.item.isActiveName"
              class="
                label
                font-weight-bold
                label-lg label-light-success label-inline
              "
            ></span>
            <span
              v-else
              v-text="row.item.isActiveName"
              class="
                label
                font-weight-bold
                label-lg label-light-danger label-inline
              "
            ></span>
          </template>
          <template v-slot:cell(createdAt)="row">
            <p class="text-center">{{ row.item.createdAt }}</p>
            <p class="text-center">{{ row.item.createdBy }}</p>
          </template>
          <template v-slot:cell(actions)="row">
            <div class="d-flex justify-content-center">
              <b-dropdown size="sm" id="dropdown-left" no-caret right>
                <template slot="button-content">
                  <i
                    style="font-size: 1rem; padding-right: 0px"
                    class="flaticon2-settings"
                  ></i>
                </template>
                <b-dropdown-item
                  @click="editItem(row.item.id)"
                  v-if="!checkPermission('VIRTUAL_STOCK_UPDATE')"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 10px" class="flaticon-eye"></i>
                    &nbsp; Chi tiết
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="editItem(row.item.id)"
                  v-if="checkPermission('VIRTUAL_STOCK_UPDATE')"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 1rem" class="flaticon2-pen"></i>
                    &nbsp; Chỉnh sửa
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showDeleteAlert(row.item.id)"
                  v-show="checkPermission('VIRTUAL_STOCK_DELETE')"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i
                      style="font-size: 1rem; color: #d33"
                      class="flaticon2-rubbish-bin-delete-button"
                    ></i>
                    &nbsp; Xóa
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>
        <b-row>
          <b-col>
            <p class="mt-3 text-dark" style="font-weight: 500">
              Tổng số:
              {{ totalRow }}
            </p>
          </b-col>
          <b-col>
            <b-pagination-nav
              v-show="numberOfPage >= 2"
              class="custom-pagination"
              :link-gen="linkGen"
              :number-of-pages="numberOfPage"
              use-router
              @change="fetchData()"
              align="right"
              first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
              prev-class="page-item-prev btn btn-icon btn-sm mr-1 my-1"
              next-class="page-item-next btn btn-icon btn-sm mr-1 my-1"
              last-class="page-item-last btn btn-icon btn-sm my-1"
              page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
            ></b-pagination-nav>
          </b-col>
        </b-row>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import localData from '../../../utils/saveDataToLocal';
import { VclTable } from 'vue-content-loading';
import Swal from 'sweetalert2';
import ApiService from '@/core/services/api.service';

export default {
  data() {
    return {
      searchProduct: '',
      totalRow: 0,
      numberOfPage: 1,
      listItem: [],
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      page: 1,
      fields: [
        {
          key: 'name',
          label: 'Nhóm sản phẩm',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '30%' },
        },
        {
          key: 'stores',
          label: 'Cửa hàng',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '30%' },
        },
        {
          key: 'isActive',
          label: 'Trạng thái',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' },
        },
        {
          key: 'createdAt',
          label: 'Ngày tạo',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
        { key: 'actions', label: '' },
      ],
      onLoading: false,
      listStatus: [
        {
          id: null,
          name: 'Tất cả trạng thái',
        },
        {
          id: 1,
          name: 'Hoạt động',
        },
        {
          id: 0,
          name: 'Khóa',
        },
      ],
      selectedStatus: null,
      searchName: '',
    };
  },
  components: {
    KTCodePreview,
    VclTable,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Cấu hình tồn ảo sản phẩm' },
    ]);
  },
  created() {
    this.fetchData();
  },
  methods: {
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    showDeleteAlert: function (id) {
      Swal.fire({
        title: 'Xóa cấu hình tồn ảo sản phẩm!',
        text: 'Bạn có chắc muốn xóa cấu hình tồn ảo sản phẩm này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
        position: 'top',
      }).then((result) => {
        if (result.value) {
          this.deleteItem(id);
        }
      });
    },
    fetchData: async function () {
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }

      const param = {
        page: this.page,
        limit: 10,
        searchProduct: this.searchProduct,
        searchName: this.searchName,
        selectedStatus: this.selectedStatus,
      };

      const paramQuery = {
        params: param,
      };

      this.onLoading = true;

      ApiService.query('virtual-stocks', paramQuery)
        .then(({ data }) => {
          this.totalRow = data.data.totalRow;
          this.numberOfPage = data.data.totalPage;
          this.listItem = data.data.data;
          this.onLoading = false;
        })
        .catch(() => {
          this.onLoading = false;
        });
    },
    onFilter() {
      this.$route.query.page = 1;
      this.$router.push({
        name: 'list-virtual-stock',
      });
      this.fetchData();
    },
    addNew() {
      this.$router.push({
        name: 'info-virtual-stock',
      });
    },
    hideModal() {
      this.$refs['virtual-stock-modal'].hide();
    },
    editItem: async function (id) {
      this.$router.push({
        name: 'info-virtual-stock',
        query: { id: id },
      });
    },
    deleteItem: async function (id) {
      ApiService.delete(`virtual-stocks/${id}`).then((data) => {
        const { status, message } = data.data;
        if (status === 1) {
          this.makeToastSuccess(message);
          this.fetchData();
        } else {
          this.makeToastFaile(message);
        }
      });
    },
  },
};
</script>

<style>
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}
.table.b-table.table-sm > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table.table-sm
  > tfoot
  > tr
  > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(1.5rem / 2) center;
  padding-right: calc(0.3rem + 0.65em);
}

.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
  overflow: scroll;
  max-height: 200px;
  position: absolute;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

#autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e5eaee;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

#autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}

.form-group-label {
  font-weight: 500;
}
</style>